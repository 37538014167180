import { useState } from "react";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/lab/Autocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { changeRole } from "services/userService";

function MemberRole(props) {
	const { handleClose, open, updateMembers, userId } = props;

	const roles = [
		{ id: "7", name: "User", value: "User" },
		{ id: "8", name: "Clinician", value: "Clinician" },
		{ id: "9", name: "Lawyer", value: "Lawyer" },
		{ id: "10", name: "PDSS", value: "PDSS" },
		{ id: "11", name: "Tech", value: "Tech" },
		{ id: "12", name: "Admin", value: "Admin" },
		{ id: "14", name: "Super-Admin", value: "Super-Admin" },
	];
	const [selectedRole, setSelectedRole] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const handleRoleChange = (event, newValue) => {
		setSelectedRole(newValue);
	};

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		try {
			const res = await changeRole(userId, {
				role: selectedRole.value,
			});
			const { message, status } = res;
			toast(message, { type: status, autoClose: 3000 });
			updateMembers();
			handleClose();
			setIsLoading(false);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
			setIsLoading(false);
		}
	};

	return (
		<Dialog fullWidth={true} open={open} onClose={handleClose}>
			<DialogTitle>Change User Role</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div>
						<MDBox pt={2} px={2}>
							<div>
								<Autocomplete
									value={selectedRole}
									onChange={handleRoleChange}
									options={roles || []}
									getOptionLabel={(option) => (option.name ? option.name : option)}
									renderInput={(params) => (
										<MDInput
											{...params}
											type="text"
											label="Select Role"
											variant="standard"
											fullWidth
										/>
									)}
								/>
							</div>
							<MDBox
								mt={4}
								mb={3}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<MDButton variant="gradient" color="dark" onClick={handleClose}>
									Close
								</MDButton>{" "}
								&nbsp; &nbsp;
								<MDButton
									type="submit"
									variant="gradient"
									color="info"
									disabled={isLoading}
									onClick={handleFormSubmit}
								>
									{isLoading ? "Loading..." : "Change Role"}
								</MDButton>
							</MDBox>
						</MDBox>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
}

export default MemberRole;
