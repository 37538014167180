import httpService from "./httpService";

export const getTotalClinics = async (year, state) => {
	let url = `/analytics/total-clinics`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getActiveLocationsCount = async (year, state) => {
	let url = `/analytics/active-locations`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getTotalMembers = async (year, state) => {
	let url = `/analytics/total-members`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getGenderDistribution = async (year, state) => {
	let url = `/analytics/gender-dist`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getPWDDistribution = async (year, state) => {
	let url = `/analytics/pwd-distribution`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getPlacesOfArrestData = async (year, state) => {
	let url = `/analytics/places-of-arrest`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getCustodialCentersData = async (year, state) => {
	let url = `/analytics/custodial-centers`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getDateOfArrestData = async (year, state) => {
	let url = `/analytics/date-of-arrest`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getIncomeLevelData = async (year, state) => {
	let url = `/analytics/income-level`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};


export const getConferenceData = async (year, state) => {
	let url = `/analytics/case-conference-data`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getCaseReviewData = async (year, state) => {
	let url = `/analytics/case-review-data`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getDetaineeData = async (year, state) => {
	let url = `/analytics/detainee-data`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};

export const getDetaineeWeeklySummary = async (year, state) => {
	let url = `/analytics/detainee-weekly`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
export const getReviewWeeklySummary = async (year, state) => {
	let url = `/analytics/review-weekly`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};
export const getConferenceWeeklySummary = async (year, state) => {
	let url = `/analytics/conference-weekly`;
	const params = [];
	try {
		if (state) {
			params.push(`state=${encodeURIComponent(state)}`);
		}
		if (year) {
			params.push(`year=${encodeURIComponent(year)}`);
		}

		if (params.length > 0) {
			url += `?${params.join("&")}`;
		}
		const response = await httpService.get(url);
		return response.data;
	} catch (err) {
		if (err.response) {
			throw new Error(
				err.response.data.message || "Something went wrong. Please try again",
			);
		}
		throw new Error(err.message || "An unknown error occurred");
	}
};