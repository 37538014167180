import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, CircularProgress, Icon, Menu, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import DataTable from "utilities/Tables/DataTable";
import { getAllPdssReports } from "services/pdssService";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useAuth } from "context/auth";
import { safeDateFormat } from "utils";

const SwalDialog = withReactContent(
    Swal.mixin({
        customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-gray",
        },
        buttonsStyling: false,
    }),
);

const PdssReport = () => {
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const res = await getAllPdssReports();
                const { total, reports } = res.data;
                setReportData(reports);
            } catch (error) {
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchReports();
    }, []);

    const handleViewDetails = (report) => {
        console.log("Selected Report:", report);
        setSelectedReport(report);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedReport(null);
    };

    const deleteHandler = async (id) => {
        const result = await SwalDialog.fire({
            title: "Confirm Delete",
            text: "You are about to delete this report",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        });

        if (result.isConfirmed) {
            // TO do
            // Delete the guy
            // Then refresh to get the other guys without the deleted one
            await SwalDialog.fire("Deleted!", "Your report has been deleted.", "success");
        }
    };

    const DropdownMenu = ({ report }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleOpenMenu = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleDelete = () => {
            deleteHandler(report._id);
            handleCloseMenu();
        };

        return (
            <>
                <IconButton onClick={handleOpenMenu}>
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    <MenuItem onClick={() => handleViewDetails(report)}>
                        <IconButton>
                            <Icon>visibility</Icon>
                        </IconButton>
                        View
                    </MenuItem>
                    {["Admin", "Super-Admin", "Admin", "Tech"].includes(user?.role) && (
                        <MenuItem onClick={handleDelete}>
                            <IconButton>
                                <Icon>delete</Icon>
                            </IconButton>
                            Delete
                        </MenuItem>
                    )}
                </Menu>
            </>
        );
    };

    const columns = [
        { Header: "Date", accessor: "date" },
        { Header: "Name of Duty Solicitor", accessor: "dutySolicitor" },
        { Header: "Name of Police Division", accessor: "policeDivision" },
        { Header: "Detainee Count", accessor: "detaineeCount" },
        { Header: "action", accessor: "action" },
    ];

    const rows = reportData.map((report) => {
        const detaineeCount = report.weeklyObservation.details.reduce((acc, detail) => {
            return acc + detail.detainees.length;
        }, 0);

        return {
            date: safeDateFormat(new Date(report?.date), "dd/MM/yyyy"),
            dutySolicitor: report.nameOfDutySolicitor,
            policeDivision: report.nameOfPoliceDivision,
            detaineeCount,
            action: <DropdownMenu report={report} />,
        };
    });

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" style={{ color: 'white' }}>
                            PDSS Report
                        </Typography>
                        {["PDSS", "Tech"].includes(user?.role) && (
                            <MDButton
                                variant="outlined"
                                onClick={() => navigate("/pdss-report-form")}
                            >
                                Add New Report
                            </MDButton>
                        )}
                    </MDBox>
                    {loading ? (
                        <MDBox py={3} px={2}>
                            <CircularProgress color="info" />
                        </MDBox>
                    ) : (
                        <>
                            {reportData.length > 0 ? (
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
                                    showTotalEntries={true}
                                />
                            ) : (
                                <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    No PDSS report added.
                                </Typography>
                            )}
                        </>
                    )}
                </Card>
            </Grid>

            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Report Details</DialogTitle>
                <DialogContent>
                    {selectedReport && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                                    Duty Solicitor: {selectedReport.nameOfDutySolicitor || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                                    Police Division: {selectedReport.nameOfPoliceDivision || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                                    Date: {safeDateFormat(new Date(selectedReport.date), "dd/MM/yyyy") || "N/A"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
                                    Detainee Count: {selectedReport.weeklyObservation.details.reduce((acc, detail) => acc + detail.detainees.length, 0) || 0}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    Weekly Observations
                                </Typography>
                                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #ccc', padding: '8px', backgroundColor: '#f5f5f5' }}>Day</th>
                                            <th style={{ border: '1px solid #ccc', padding: '8px', backgroundColor: '#f5f5f5' }}>Detainees</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedReport.weeklyObservation.details.map((detail, index) => (
                                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{detail.day}</td>
                                                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                                    <ul style={{ margin: 0, padding: 0 }}>
                                                        {detail.detainees.map((detainee, idx) => (
                                                            <li key={idx} style={{ margin: '4px 0' }}>
                                                                {detainee.name} (Serial: {detainee.serialNumber})
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="info" variant="contained">Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default PdssReport; 