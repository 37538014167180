import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Grid, Card, Container, Modal, Button } from "@mui/material";
import { createPdssReport } from "services/pdssService";
import { toast } from "react-toastify";

const PdssReportForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nameOfDutySolicitor: "",
        nameOfPoliceDivision: "",
        nameAndPhoneNumberOfDPO: "",
        nameAndPhoneNumberOfIPO: "",
        nameAndPhoneNumberOfOCLegal: "",
        nameAndPhoneNumberOfDCO: "",
        date: "",
        weeklyObservation: {
            introduction: "",
            details: Array(5).fill({ day: "", detainees: [] }),
        },
    });
    const [openModal, setOpenModal] = useState(false);
    const [currentDayIndex, setCurrentDayIndex] = useState(null);
    const [newDetainee, setNewDetainee] = useState({
        serialNumber: "",
        name: "",
        gender: "",
        offenceCommitted: "",
        dateOfArrest: "",
        age: "",
        chargedToCourt: false,
        lawyerAssigned: false,
        detentionDuration: "",
        externalLawyerAssigned: false,
        bailGranted: false,
        facilitatorOfBail: "",
    }); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleObservationChange = (index, day) => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[index] = { ...newDetails[index], day, detainees: newDetails[index].detainees || [] };
        setFormData({
            ...formData,
            weeklyObservation: { ...formData.weeklyObservation, details: newDetails }
        });
    };


    const handleDetaineeChange = (dayIndex, detaineeIndex, field, value) => {
        const newDetails = [...formData.weeklyObservation.details];
        const detainee = newDetails[dayIndex].detainees[detaineeIndex] || {};
        newDetails[dayIndex].detainees[detaineeIndex] = { ...detainee, [field]: value };
        setFormData({ ...formData, weeklyObservation: { ...formData.weeklyObservation, details: newDetails } });
    };

    const handleOpenModal = (dayIndex) => {
        setCurrentDayIndex(dayIndex);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewDetainee({}); 
    };

    const addDetainee = () => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[currentDayIndex].detainees.push(newDetainee);
        setFormData({ ...formData, weeklyObservation: { ...formData.weeklyObservation, details: newDetails } });
        handleCloseModal();
        setNewDetainee({});
    };

    const deleteDetainee = (dayIndex, detaineeIndex) => {
        const newDetails = [...formData.weeklyObservation.details];
        newDetails[dayIndex].detainees.splice(detaineeIndex, 1); // Remove detainee
        setFormData({ ...formData, weeklyObservation: { ...formData.weeklyObservation, details: newDetails } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createPdssReport(formData);
            toast.success("Report created successfully!");
            
            navigate("/report");
            
            setFormData({
                nameOfDutySolicitor: "",
                nameOfPoliceDivision: "",
                nameAndPhoneNumberOfDPO: "",
                nameAndPhoneNumberOfIPO: "",
                nameAndPhoneNumberOfOCLegal: "",
                nameAndPhoneNumberOfDCO: "",
                date: "",
                weeklyObservation: {
                    introduction: "",
                    details: Array(5).fill({ day: "", detainees: [] }),
                },
            });
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white" fontWeight="medium" textTransform="capitalize">
                                    PDSS Report Form
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={4} pb={3}>
                                <Container>
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameOfDutySolicitor"
                                                    label="Name of Duty Solicitor"
                                                    fullWidth
                                                    value={formData.nameOfDutySolicitor}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameOfPoliceDivision"
                                                    label="Name of Police Division"
                                                    fullWidth
                                                    value={formData.nameOfPoliceDivision}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfDPO"
                                                    label="Name and Phone Number of DPO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfDPO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfIPO"
                                                    label="Name and Phone Number of IPO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfIPO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfOCLegal"
                                                    label="Name and Phone Number of OC/Legal"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfOCLegal}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="nameAndPhoneNumberOfDCO"
                                                    label="Name and Phone Number of DCO"
                                                    fullWidth
                                                    value={formData.nameAndPhoneNumberOfDCO}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    name="date"
                                                    label="Date"
                                                    type="date"
                                                    fullWidth
                                                    value={formData.date}
                                                    onChange={handleChange}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <MDTypography variant="h6" color="black" fontWeight="medium" textTransform="capitalize">
                                                    Observations
                                                </MDTypography>
                                                <MDInput
                                                    name="introduction"
                                                    label="Introduction"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    value={formData.weeklyObservation.introduction}
                                                    onChange={(e) => setFormData({ ...formData, weeklyObservation: { ...formData.weeklyObservation, introduction: e.target.value } })}
                                                />
                                            </Grid>
                                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map((day, index) => (
                                                <Grid item xs={12} sm={6} key={index}>
                                                    <MDInput
                                                        label={`Observations for ${day}`}
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        value={formData.weeklyObservation.details[index].day}
                                                        onChange={(e) => handleObservationChange(index, e.target.value)}
                                                    />
                                                    {/* Detainee List */}
                                                    {formData.weeklyObservation.details[index].detainees.map((detainee, detaineeIndex) => (
                                                        <div key={detaineeIndex}>
                                                            <span>{detainee.name || "Unnamed Detainee"}</span>
                                                            <Button onClick={() => deleteDetainee(index, detaineeIndex)} color="secondary">X</Button>
                                                        </div>
                                                    ))}
                                                    <MDButton onClick={() => handleOpenModal(index)} variant="outlined" color="primary" size="small">
                                                        Add Detainee
                                                    </MDButton>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12}>
                                                <MDButton type="submit" variant="contained" color="info">
                                                    Submit Report
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Container>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />

            {/* Modal for Adding Detainee */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', marginTop: '100px', width: '400px' }}>
                    <h2>Add Detainee</h2>
                    <MDInput
                        label="Name"
                        fullWidth
                        style={{ marginBottom: '10px' }}
                        value={newDetainee.name}
                        onChange={(e) => setNewDetainee({ ...newDetainee, name: e.target.value })}
                    />
                    <MDInput
                        label="Serial Number"
                        fullWidth
                        type="number"
                        style={{ marginBottom: '10px' }}
                        value={newDetainee.serialNumber}
                        onChange={(e) => setNewDetainee({ ...newDetainee, serialNumber: e.target.value })}
                    />
                    <MDInput
                        label="Gender"
                        fullWidth
                        select
                        value={newDetainee.gender}
                        onChange={(e) => setNewDetainee({ ...newDetainee, gender: e.target.value })}
                        style={{ marginBottom: '10px' }}
                    >
                        <option value="" disabled>
                            Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </MDInput>

                    <MDInput
                        label="Offence Committed"
                        fullWidth
                        style={{ marginBottom: '10px' }}
                        value={newDetainee.offenceCommitted}
                        onChange={(e) => setNewDetainee({ ...newDetainee, offenceCommitted: e.target.value })}
                    />
                    <MDInput
                        name="date"
                        label="Date"
                        type="date"
                        fullWidth
                        value={formData.date || ""}
                        placeholder="dd/mm/yy"
                        onChange={handleChange}
                    />
                    <MDInput
                        label="Age"
                        fullWidth
                        type="number"
                        style={{ marginBottom: '10px' }}
                        value={newDetainee.age}
                        onChange={(e) => setNewDetainee({ ...newDetainee, age: e.target.value })}
                    />
                    <MDButton onClick={addDetainee} variant="contained" color="primary">
                        Add Detainee
                    </MDButton>
                </div>
            </Modal>
        </DashboardLayout>
    );
};

export default PdssReportForm; 