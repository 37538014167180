import { useState, useEffect } from "react";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card, Typography, Container } from "@mui/material";
import { toast } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { createDetaineeReport } from "services/detaineeFormService";
import { getAllClinics } from "../../services/clinicService";
import Editor from "components/Editor";
import { set } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getAllUsers } from "services/userService";
import PrintableAndDownloadable from "components/print-download";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function DetaineeForm() {
	const [clinicianRecommendation, setClinicianRecommendation] = useState("");
	const [supervisorRecommendation, setSupervisorRecommendation] = useState("");
	const [caseId, setCaseId] = useState("");
	const [rpdnId, setRpdnId] = useState("");
	const [description, setDescription] = useState("");
	const [placeOfInterview, setPlaceOfInterview] = useState("");
	const [dateOfInterview, setDateOfInterview] = useState(new Date().toISOString().split('T')[0]);
	const [timeOfInterview, setTimeOfInterview] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
	const [custodialCenter, setCustodialCenter] = useState({
		name: "",
		location: "",
	});
	const [clientInformation, setClientInformation] = useState({
		name: "",
		email: "",
		phone: "",
		age: "",
		address: "",
		employmentStatus: "",
		incomeLevel: "",
		anyPreviousLegalRep: "",
		consentToLegalRep: "",
		reason: "",
	});
	const [legalIssues, setLegalIssues] = useState({
		caseType: "",
		desiredOutcome: "",
		description: "",
		dateOfArrest: Date.now(),
		placeOfArrest: "",
		arrestingAgency: "",
		prosecutingAgency: "",
		firstPlaceOfDetention: "",
		bailGranted: "",
		whyStillInCustody: "",
		firstDateInCourt: Date.now(),
		lastAdjournedDate: Date.now(),
		nextDateInCourt: Date.now(),
		court: "",
		courtLocation: "",
		nameOfJudgeOrMagistrate: "",
		nameOfProsecutor: "",
		priorLegalRepresentation: "",
		previousAttemptToResolve: "",
	});
	const [additionalInformation, setAdditionalInformation] = useState({
		factsOfTheCase: "",
		conflictsOfInterest: "",
		clientSignature: "",
		translation: "",
	});
	const [locationState, setLocationState] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [clinics, setClinics] = useState([]);
	const [selectedClinic, setSelectedClinic] = useState(null);
	const [formStep, setFormStep] = useState(1);
	const [members, setMembers] = useState([]);
	const [selectedMember, setSelectedMember] = useState(null);
	const navigate = useNavigate();
	const [openLocationDialog, setOpenLocationDialog] = useState(true);
	const [locationOption, setLocationOption] = useState("none");

	const stateData = NaijaStates.states().map((item, idx) => {
		return {
			id: idx,
			name:
				item === "FCT"
					? "Federal Capital Territory"
					: item === "Federal Capital Territory"
						? "Federal Capital Territory"
						: item + " State",
			value: item + " State",
		};
	});

	const fetchClinics = async () => {
		try {
			const res = await getAllClinics();
			const { data } = res.data;
			setClinics(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	const fetchMembers = async () => {
		try {
			const res = await getAllUsers();
			const { data } = res.data;
			setMembers(data);
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	};

	useEffect(() => {
		fetchClinics();
		fetchMembers();
	}, []);

	const handleClinicChange = (event, newValue) => {
		setSelectedClinic(newValue);
	};

	const handleMemberChange = (event, newValue) => {
		setSelectedMember(newValue);
	};

	const handleStateChange = (event, value) => {
		setLocationState(value ? value.name : null);
	};

	const fetchLocation = async () => {
		if (!navigator.geolocation) {
			toast("Geolocation is not supported by this browser.", {
				type: "error",
				autoClose: 3000,
			});
			return;
		}

		// show loader
		setLoading(true);

		// Request permission for location access
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				setLatitude(latitude);
				setLongitude(longitude);
				reverseGeocode(latitude, longitude);
				setLoading(false);
			},
			(error) => {
				console.error("Geolocation error:", error);
				toast("Unable to retrieve your location: " + error.message, {
					type: "error",
					autoClose: 3000,
				});
				setLoading(false);
			},
			{ enableHighAccuracy: true } // Optional: request high accuracy
		);
	};

	const reverseGeocode = (latitude, longitude) => {
		const geocoder = new window.google.maps.Geocoder();
		const latlng = new window.google.maps.LatLng(latitude, longitude);

		geocoder.geocode({ location: latlng }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					const addressComponents = results[0].address_components;
					const state = addressComponents.find((item) =>
						item.types.includes("administrative_area_level_1"),
					);
					setLocationState(state.long_name || "Unknown State");
				} else {
					setLocationState("Please select a state");
				}
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		fetchLocation();
	}, [fetchLocation]);

	const handleFormNext = () => {
		setFormStep(2);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handleFormBack = () => {
		setFormStep(1);
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const handleFormClear = () => {
		// Reset form
		setClinicianRecommendation("");
		setSupervisorRecommendation("");
		setSelectedClinic(null);
		setDateOfInterview(new Date().toISOString().split('T')[0]);
		setTimeOfInterview(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
		setClientInformation({
			name: "",
			email: "",
			phone: "",
			age: "",
			address: "",
			employmentStatus: "",
			incomeLevel: "",
			anyPreviousLegalRep: "",
			consentToLegalRep: "",
			reason: "",
		});
		setLegalIssues({
			caseType: "",
			desiredOutcome: "",
			description: "",
			dateOfArrest: "",
			placeOfArrest: "",
			arrestingAgency: "",
			prosecutingAgency: "",
			firstPlaceOfDetention: "",
			bailGranted: "",
			whyStillInCustody: "",
			firstDateInCourt: "",
			lastAdjournedDate: "",
			nextDateInCourt: "",
			court: "",
			courtLocation: "",
			nameOfJudgeOrMagistrate: "",
			nameOfProsecutor: "",
			priorLegalRepresentation: "",
			previousAttemptToResolve: "",
		});
		setAdditionalInformation({
			factsOfTheCase: "",
			conflictsOfInterest: "",
			clientSignature: "",
			translation: "",
		});
		setLocationState("");
	};

	const handleCancel = () => {
		handleFormClear();
		window.localStorage.setItem("currentTabIndex", 0);
		navigate(-1);
	};

	const handleFormSubmit = async (event) => {
		setIsLoading(true);
		event.preventDefault();

		const formData = {
			caseId: caseId,
			rpdnId: rpdnId,
			location: {
				state: locationState,
				latitude,
				longitude,
			},
			custodialCenter,
			clinicName: selectedClinic?.name,
			clinicianName: selectedMember?.name,
			description,
			placeOfInterview,
			dateOfInterview,
			timeOfInterview,
			clientInformation,
			legalIssues,
			additionalInformation,
			clinicianRecommendation,
			supervisorRecommendation,
		};

		try {
			const res = await createDetaineeReport(formData);
			const { message, status } = res;
			if (status === "success") {
				toast(message, { type: "success", autoClose: 3000 });
				handleFormClear();
				setIsLoading(false);
				navigate("/report");
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (error) {
			toast(error.message, { type: "error", autoClose: 3000 });
		} finally {
			setIsLoading(false);
		}
	};

	const handleLocationSelection = (option) => {
		setLocationOption(option);
		setOpenLocationDialog(false);
		if (option === "pick") {
			fetchLocation();
		}
	};

	useEffect(() => {
		setOpenLocationDialog(true);
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<PrintableAndDownloadable filename="detaineeform.pdf">
				<MDBox pt={6} pb={3}>
					<Dialog open={openLocationDialog} onClose={() => setOpenLocationDialog(false)}>
						<DialogTitle>
							<MDTypography variant="h6" align="center">Select Location</MDTypography>
						</DialogTitle>
						<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
							<MDTypography variant="body1" align="center">
								Click to pick your current location
							</MDTypography>
						</DialogContent>
						<DialogActions sx={{ justifyContent: 'center' }}>
							<MDButton onClick={() => handleLocationSelection("pick")} color="info">
								Pick Location
							</MDButton>
							<MDButton onClick={() => handleLocationSelection("none")} color="default">
								Proceed Without Location
							</MDButton>
						</DialogActions>
					</Dialog>

					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography
										variant="h6"
										color="white"
										fontWeight="medium"
										textTransform="capitalize"
									>
										Detainee Form
									</MDTypography>
								</MDBox>

								<MDBox pt={4} pb={3}>
									<Container>
										<Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
											{formStep === 1
												? "Step 1: General and Client Information"
												: "Step 2: Legal Issues"}
										</Typography>

										{formStep === 1 && (
											<>
												{/* General */}
												<Grid container spacing={3}>
													<Grid item xs={12}>
														<Typography variant="h6">Section A: General</Typography>
														<Typography variant="subtitle2">
															<strong>Current location - </strong> {(longitude && latitude) ? (
																<span>Latitude: {latitude}, Longitude: {longitude}</span>
															) : <span>Not selected (<i>Refresh page to pick location</i>)</span>}
														</Typography>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="RPDN ID"
															fullWidth
															value={rpdnId}
															onChange={(e) => setRpdnId(e.target.value)}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Case ID"
															fullWidth
															value={caseId}
															onChange={(e) => setCaseId(e.target.value)}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<div>
															<Autocomplete
																value={
																	stateData.find((item) => item.name === locationState) || null
																}
																onChange={handleStateChange}
																options={stateData}
																getOptionLabel={(option) => option.name}
																renderInput={(params) => (
																	<MDInput
																		{...params}
																		type="text"
																		label="Location State"
																		variant="standard"
																		fullWidth
																	/>
																)}
															/>
														</div>
													</Grid>

													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Custodial Center Name"
															fullWidth
															value={custodialCenter.name}
															onChange={(e) =>
																setCustodialCenter({
																	...custodialCenter,
																	name: e.target.value,
																})
															}
														/>
													</Grid>

													{/* {locationOption === "pick" && (
														<Grid container spacing={3}>
															<Grid item xs={12} sm={6}>
																<MDInput
																	type="text"
																	variant="standard"
																	label="Latitude"
																	fullWidth
																	value={latitude}
																	readOnly
																/>
															</Grid>
															<Grid item xs={12} sm={6}>
																<MDInput
																	type="text"
																	variant="standard"
																	label="Longitude"
																	fullWidth
																	value={longitude}
																	readOnly
																/>
															</Grid>
														</Grid>
													)} */}

													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Place of interview"
															fullWidth
															value={placeOfInterview}
															onChange={(e) => setPlaceOfInterview(e.target.value)}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<MDInput
															type="date"
															variant="standard"
															label="Date of Interview"
															fullWidth
															value={dateOfInterview}
															onChange={(e) => setDateOfInterview(e.target.value)}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="time"
															variant="standard"
															label="Time of Interview"
															fullWidth
															value={timeOfInterview}
															onChange={(e) => setTimeOfInterview(e.target.value)}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<Autocomplete
															value={selectedClinic}
															onChange={handleClinicChange}
															options={clinics || []}
															getOptionLabel={(option) => (option.name ? option.name : "")}
															renderInput={(params) => (
																<MDInput
																	{...params}
																	type="text"
																	label="Clinic Name"
																	variant="standard"
																	fullWidth
																/>
															)}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<Autocomplete
															options={members || []}
															value={selectedMember}
															onChange={handleMemberChange}
															getOptionLabel={(option) => (option.name ? option.name : "")}
															renderInput={(params) => (
																<MDInput
																	{...params}
																	type="text"
																	label="Clinician"
																	variant="standard"
																	fullWidth
																/>
															)}
														/>
													</Grid>
												</Grid>

												<Grid container spacing={3} sx={{ my: 2 }}>
													<Grid item xs={12}>
														<Typography variant="h6">
															Section B: Client Information
														</Typography>
													</Grid>

													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="Name"
															fullWidth
															value={clientInformation.name}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	name: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item sx={12} sm={6}>
														<MDInput
															type="email"
															variant="standard"
															label="Email"
															fullWidth
															value={clientInformation.email}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	email: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<MDInput
															type="tel"
															variant="standard"
															label="Phone"
															fullWidth
															value={clientInformation.phone}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	phone: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="Address"
															fullWidth
															value={clientInformation.address}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	address: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Age"
															fullWidth
															value={clientInformation.age}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	age: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Employment Status"
															fullWidth
															value={clientInformation.employmentStatus}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	employmentStatus: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={4}>
														<MDInput
															type="text"
															variant="standard"
															label="Income Level"
															fullWidth
															value={clientInformation.incomeLevel}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	incomeLevel: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={4}>
														<MDInput
															type="text"
															variant="standard"
															label="Any Previous Legal Representation"
															fullWidth
															value={clientInformation.anyPreviousLegalRep}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	anyPreviousLegalRep: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12} sm={4}>
														<MDInput
															type="text"
															variant="standard"
															label="Consent to Legal Representation"
															fullWidth
															value={clientInformation.consentToLegalRep}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	consentToLegalRep: e.target.value,
																})
															}
														/>
													</Grid>

													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="If No Consent, please state reason"
															fullWidth
															value={clientInformation.reason}
															onChange={(e) =>
																setClientInformation({
																	...clientInformation,
																	reason: e.target.value,
																})
															}
														/>
													</Grid>
												</Grid>
											</>
										)}

										{formStep === 2 && (
											<>
												{/* Legal Issues Group */}

												<Grid container spacing={3} sx={{ my: 2 }}>
													<Grid item xs={12}>
														<MDTypography variant="h6" fontWeight="medium" gutterBottom>
															Legal Issues
														</MDTypography>
													</Grid>

													<Grid item xs={12}>
														<Editor
															title={"Brief Description"}
															placeholder={
																"(What is the charge? Why was he/she arrested? A narration of the actual incidence"
															}
															content={legalIssues.description}
															handleInputChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	description: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="date"
															variant="standard"
															label="Date of Arrest"
															fullWidth
															value={legalIssues.dateOfArrest}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	dateOfArrest: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Place of Arrest"
															fullWidth
															value={legalIssues.placeOfArrest}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	placeOfArrest: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Arresting Agency"
															fullWidth
															value={legalIssues.arrestingAgency}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	arrestingAgency: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Prosecuting Agency"
															fullWidth
															value={legalIssues.prosecutingAgency}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	prosecutingAgency: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="First Place of Detention"
															fullWidth
															value={legalIssues.firstPlaceOfDetention}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	firstPlaceOfDetention: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Bail Granted"
															fullWidth
															value={legalIssues.bailGranted}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	bailGranted: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="Why Still in Custody"
															fullWidth
															value={legalIssues.whyStillInCustody}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	whyStillInCustody: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<MDInput
															type="date"
															variant="standard"
															label="First Date in Court"
															fullWidth
															value={legalIssues.firstDateInCourt}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	firstDateInCourt: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<MDInput
															type="date"
															variant="standard"
															label="Last Adjourned Date"
															fullWidth
															value={legalIssues.lastAdjournedDate}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	lastAdjournedDate: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<MDInput
															type="date"
															variant="standard"
															label="Next Date in Court"
															fullWidth
															value={legalIssues.nextDateInCourt}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	nextDateInCourt: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Court"
															fullWidth
															value={legalIssues.court}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	court: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Court Location"
															fullWidth
															value={legalIssues.courtLocation}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	courtLocation: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Name of Judge or Magistrate"
															fullWidth
															value={legalIssues.nameOfJudgeOrMagistrate}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	nameOfJudgeOrMagistrate: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Name of Prosecutor"
															fullWidth
															value={legalIssues.nameOfProsecutor}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	nameOfProsecutor: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Prior Legal Representation"
															fullWidth
															value={legalIssues.priorLegalRepresentation}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	priorLegalRepresentation: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<MDInput
															type="text"
															variant="standard"
															label="Previous Attempt to Resolve"
															fullWidth
															value={legalIssues.previousAttemptToResolve}
															onChange={(e) =>
																setLegalIssues({
																	...legalIssues,
																	previousAttemptToResolve: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12} sx={{ mt: 4 }}>
														<MDTypography variant="h6" fontWeight="medium" gutterBottom>
															Additional Information
														</MDTypography>
													</Grid>
													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="Facts of the Case"
															fullWidth
															value={additionalInformation.factsOfTheCase}
															onChange={(e) =>
																setAdditionalInformation({
																	...additionalInformation,
																	factsOfTheCase: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<MDInput
															type="text"
															variant="standard"
															label="Conflicts of Interest"
															fullWidth
															value={additionalInformation.conflictsOfInterest}
															onChange={(e) =>
																setAdditionalInformation({
																	...additionalInformation,
																	conflictsOfInterest: e.target.value,
																})
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<Editor
															title={"Clinician Recommendation"}
															content={clinicianRecommendation}
															handleInputChange={(e) =>
																setClinicianRecommendation(e.target.value)
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<Editor
															title={"Supervisor Recommendation"}
															content={supervisorRecommendation}
															handleInputChange={(e) =>
																setSupervisorRecommendation(e.target.value)
															}
														/>
													</Grid>
												</Grid>
											</>
										)}

										{/* Buttons */}
										<MDBox
											px={2}
											mt={5}
											sx={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											{/* Left Buttons */}
											<MDBox sx={{ display: "flex" }}>
												{formStep === 1 && (
													<>
														<MDButton
															sx={{ ml: 2 }}
															variant="outlined"
															color="warning"
															onClick={() => handleFormClear()}
														>
															Clear
														</MDButton>
														<MDButton
															sx={{ ml: 2 }}
															variant="outlined"
															color="error"
															onClick={() => handleCancel()}
														>
															Cancel
														</MDButton>
													</>
												)}
												{formStep === 2 && (
													<>
														<MDButton
															sx={{ ml: 2 }}
															variant="outlined"
															color="error"
															onClick={() => handleCancel()}
														>
															Cancel
														</MDButton>
													</>
												)}
											</MDBox>

											{/* Right Button */}
											{formStep === 1 ? (
												<MDButton
													type="submit"
													variant="gradient"
													color="info"
													onClick={handleFormNext}
												>
													Next
												</MDButton>
											) : (
												<MDButton
													type="submit"
													variant="gradient"
													color="info"
													onClick={handleFormBack}
												>
													Back
												</MDButton>
											)}
											{formStep === 2 && (
												<MDButton
													sx={{ ml: 2 }}
													type="submit"
													variant="gradient"
													color="success"
													disabled={isLoading}
													onClick={handleFormSubmit}
												>
													{isLoading ? "Loading..." : "Submit"}
												</MDButton>
											)}
										</MDBox>
									</Container>
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</PrintableAndDownloadable>
			<Footer />
		</DashboardLayout>
	);
}

export default DetaineeForm;
