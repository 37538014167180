// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import ComplexStatisticsCard from "utilities/Cards/StatisticsCards/ComplexStatisticsCard";
import Map from "assets/images/illustrations/rpdn-map.svg";

// Dashboard components
import Overview from "layouts/dashboard/Overview";
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "context/auth";
import {
	getActiveLocationsCount,
	getTotalClinics,
	getTotalMembers,
} from "services/analyticsService";
import YearPicker from "components/year-picker";
import StatePicker from "components/State-picker";
import { Card, Divider, Icon, Typography, List, ListItem, ListItemText, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper } from "@mui/material";
import { isAdmin } from "utils";
import PieChart from "utilities/Charts/PieChart";
import MDTypography from "components/MDTypography";
import Ditribution from "layouts/analytics/Ditribution";
import { getAllDetaineeReport } from "services/detaineeFormService";
import { toast } from "react-toastify";

function Dashboard() {
	const { user } = useAuth();
	const [clinicData, setClinicData] = useState(null);
	const [locationData, setLocationData] = useState({ totalActiveLocations: 0 });
	const [memberData, setMemberData] = useState(0);
	const [year, setYear] = useState(new Date().getFullYear());
	const [state, setState] = useState("");

	const [recentDetaineesData, setRecentDetaineesData] = useState([]);
	const [loadingData, setLoadingData] = useState(false);

	const recentDetaineesDummy = [
		{ name: "Harley Quinn", placeOfArrest: "Gotham", custodialCenter: "Arkham Asylum", clinician: "Dr. Hugo Strange" },
		{ name: "The Joker", placeOfArrest: "Gotham", custodialCenter: "Arkham Asylum", clinician: "Dr. Harleen Quinzel" },
		{ name: "Barry Allen", placeOfArrest: "Central City", custodialCenter: "Iron Heights", clinician: "Dr. Wells" },
		{ name: "Oliver Queen", placeOfArrest: "Star City", custodialCenter: "Starling City Prison", clinician: "Dr. Emil Hamilton" },
		{ name: "Dinah Lance", placeOfArrest: "Gotham", custodialCenter: "Blackgate Penitentiary", clinician: "Dr. Mid-Nite" },
	];


	useEffect(() => {
		const userState = user?.state;

		if (!isAdmin(user)) {
			setState(userState);
		}
	}, [user]);

	const fetchData = useCallback(async () => {
		try {
			const [cRes, lRes, mRes] = await Promise.all([
				getTotalClinics("", ""),
				getActiveLocationsCount("", ""),
				getTotalMembers("", ""),
			]);

			const totalMembers = mRes.data.groups ? mRes.data.groups.reduce(
				(total, group) => total + group.memberCount,
				0,
			) : 0;

			setLocationData(lRes.data);
			setClinicData(cRes?.data?.totalClinics);
			setMemberData(totalMembers);
		} catch (error) {
			console.log("Dashboard data fetch_error:", error);
		}
	}, []);

	const fetchReports = useCallback(async () => {
		setLoadingData(true);
		try {
			const res = await getAllDetaineeReport("", "", "", "");
			const { data } = res.data;
			console.log('reports', data);
			setRecentDetaineesData(data || []);
			setLoadingData(false);
		} catch (error) {
			setLoadingData(false);
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	}, []);


	useEffect(() => {
		fetchData();
		fetchReports();
	}, [year, state, fetchData, fetchReports]);


	return (
		<>
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox py={5}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="account_balance"
									title="LAW CLINICS"
									count={clinicData ? clinicData : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of clinics",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="person"
									title="Members (Lawyers and Interns)"
									count={memberData ? memberData : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of members",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="location_on"
									title="Active Locations"
									count={locationData ? locationData.totalActiveLocations : 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of active locations",
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<MDBox mt={3}>
						<Card>
							<MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
								<MDBox
									variant="gradient"
									bgColor={'info'}
									color={"light" ? "dark" : "white"}
									coloredShadow="info"
									borderRadius="xl"
									display="flex"
									justifyContent="center"
									alignItems="center"
									width="4rem"
									height="4rem"
									mt={-3}
								>
									<Icon fontSize="medium" color="inherit">
										map
									</Icon>
								</MDBox>
							</MDBox>

							<MDBox pb={4} px={3}>
								<Grid container spacing={5}>
									<Grid item xs={12} md={5} display="flex" justifyContent="flex-end">
										<MDBox>
											<MDTypography variant="h6" fontWeight="medium">
												All Locations
											</MDTypography>
											<MDTypography variant="subtitle2" mb={2} width="80%">
												FCT, Adamawa, Kaduna, Nasarawa, Plateau, Lagos
											</MDTypography>
											<img src={Map} alt="Map" width={320} />
										</MDBox>
									</Grid>
									<Grid item xs={12} md={7}>
										<MDBox>
											<Typography variant="h6" fontWeight="medium">
												Recent Detainee Reports
											</Typography>
											<Divider />

											{loadingData ? <p>Loading...</p> : recentDetaineesData.length > 0 ? (
												<TableContainer component={Paper} sx={{ maxWidth: "100%", overflowX: "auto" }}>
													<Table sx={{ minWidth: 650, tableLayout: "fixed" }} stickyHeader>
														<TableHead sx={{ display: "table-header-group" }}>
															<TableRow>
																<TableCell>Name</TableCell>
																<TableCell>Place of Arrest</TableCell>
																<TableCell>Custodial Center</TableCell>
																<TableCell>Clinician</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{recentDetaineesData.length > 0 ? (
																recentDetaineesData.map((detainee, index) => (
																	<TableRow key={index}>
																		<TableCell>{detainee?.clientInformation?.name || 'N/A'}</TableCell>
																		<TableCell>{detainee?.legalIssues?.placeOfArrest || 'N/A'}</TableCell>
																		<TableCell>{detainee?.custodialCenter?.name || 'N/A'}</TableCell>
																		<TableCell>{detainee?.clinician || detainee?.clinicName || 'N/A'}</TableCell>
																	</TableRow>
																))
															) : (
																<Typography variant="h6" fontWeight="light">No recent Detainees data found</Typography>
															)}
														</TableBody>
													</Table>
												</TableContainer>
											) : <Typography variant="h6" fontWeight="light">No recent Detainees data found</Typography>}

										</MDBox>
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</MDBox>
				</MDBox>
				<Footer />
			</DashboardLayout>
		</>
	);
}

export default Dashboard;
