import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Material Dashboard 2 React Context Provider

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppContextControllerProvider } from "context";
import { AuthProvider } from "context/auth";
import * as serviceWorker from './serviceWorker';
import "./index.css";
const container = document.getElementById("app");
const root = createRoot(container);

root.render(
	<BrowserRouter>
		<AppContextControllerProvider>
			<AuthProvider>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<App />
					<ToastContainer theme="dark" />
				</LocalizationProvider>
			</AuthProvider>
		</AppContextControllerProvider>
	</BrowserRouter>,
);
// serviceWorker.register();
