import * as React from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "utilities/Footer";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import { getAllUsers } from "services/userService";
import { toast } from "react-toastify";

import NewMember from "components/create-member";
import { useAuth } from "context/auth";
import AllMembers from "./all-members";
import Defaulters from "./defaulters";
import Alumni from "./alumni";
import { isAdmin } from "utils";

function Members() {
	const { user } = useAuth();
	const [open, setOpen] = React.useState(false);
	const [tabIndex, setTabIndex] = React.useState(0); // Tab index for tracking selected tab
	const [members, setMembers] = React.useState([]);
	const [tab, setTab] = React.useState(""); // Tab state for filtering
	const [keyword, setKeyword] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	// Update tab state and keyword based on selected tab index and user role
	React.useEffect(() => {
		let newKeyword = "";

		// Handle keyword setting based on user role
		if (user.role === "State-Manager") {
			newKeyword = user.state;
		} else if (user.role === "Coordinator") {
			newKeyword = user.clinic || user.clinicName;
		} else if (
			["Group-Head", "Clinician", "Lawyer", "PDSS"].includes(user.role)
		) {
			newKeyword = user.group;
		}

		// Set tab based on selected tab index
		if (tabIndex === 0) {
			setTab(""); // Default tab (All Members)
		} else if (tabIndex === 1) {
			setTab("Suspended"); // Defaulters tab
		} else if (tabIndex === 2) {
			setTab("Inactive"); // Alumni tab
		}
		// Set the keyword
		setKeyword(newKeyword);
	}, [tabIndex, user]);

	// Fetch members based on tab and keyword
	const fetchMembers = React.useCallback(async () => {
		setLoading(true);
		try {
			const res = await getAllUsers("", "", tab, keyword);
			const { data } = res.data;
			setMembers(data);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast(error.message, { type: "error", autoClose: 3000 });
		}
	}, [tab, keyword]);

	// Fetch members when tab or keyword changes
	React.useEffect(() => {
		fetchMembers();
	}, [fetchMembers]);

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue); // Update the selected tab index
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<NewMember open={open} handleClose={handleClose} updateMembers={setTab} />

			<MDBox pt={6} pb={1}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" color="white">
									Members
								</MDTypography>
								{isAdmin(user) && (
									<MDButton variant="outlined" onClick={() => setOpen(true)}>
										Add Member
									</MDButton>
								)}
							</MDBox>
							<MDBox pt={6} pb={1}>
								<MDBox pb={1} px={2}>
									<Tabs
										value={tabIndex}
										onChange={handleTabChange}
										variant="scrollable"
										scrollButtons="auto"
										sx={{
											backgroundColor: "#dee2e6",
											borderRadius: "4px",
											"& .MuiTabs-indicator": {
												backgroundColor: "#1A73E8",
											},
											"& .MuiTab-root": {
												color: "#f0f2f5",
												"&.Mui-selected": {
													color: "#ffffff !important",
												},
											},
										}}
									>
										<Tab label="All" />
										<Tab label="Defaulters" />
										<Tab label="Alumni" />
									</Tabs>
								</MDBox>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<MDBox pt={1}>
											{tabIndex === 0 && (
												<AllMembers
													members={members}
													loading={loading}
													fetchMembers={fetchMembers}
												/>
											)}
											{tabIndex === 1 && (
												<Defaulters
													members={members}
													loading={loading}
													fetchMembers={fetchMembers}
												/>
											)}
											{tabIndex === 2 && (
												<Alumni
													members={members}
													loading={loading}
													fetchMembers={fetchMembers}
												/>
											)}
										</MDBox>
									</Grid>
								</Grid>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Members;
