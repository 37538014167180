import { useCallback, useEffect, useState } from "react";
import {
	Icon,
	Menu,
	MenuItem,
	IconButton,
	CircularProgress,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DataTable from "utilities/Tables/DataTable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { elipsis, safeDateFormat, isAdmin } from "utils";
import { deleteUser } from "services/userService";
import { useAuth } from "context/auth";
import MDBadge from "components/MDBadge";
import MemberStatus from "components/member-status";
import MemberRole from "components/member-role";

const SwalDialog = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

function AllMembers({ members, loading, fetchMembers }) {
	const { user } = useAuth();
	const [openRoleModal, setOpenRoleModal] = useState(false);
	const [openStatusModal, setOpenStatusModal] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const navigate = useNavigate();

	const changeUserRole = async (userId) => {
		setOpenRoleModal(true);
		setSelectedUserId(userId);
	};

	const changeUserStatus = async (userId) => {
		setOpenStatusModal(true);
		setSelectedUserId(userId);
	};

	const deleteMember = async (userId) => {
		const result = await SwalDialog.fire({
			title: "Confirm Delete",
			text: "You are about to Delete this user",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
		});

		if (result.isConfirmed) {
			try {
				const res = await deleteUser(userId);
				const { message, status } = res;
				if (status === "success") {
					await SwalDialog.fire("Success", message, "success");
					fetchMembers();
				} else {
					await SwalDialog.fire("Error", message, "error");
				}
			} catch (error) {
				await SwalDialog.fire(
					"Error",
					error.message || "Unexpected error occurred",
					"error",
				);
			}
		} else if (result.dismiss) {
			await SwalDialog.fire("Cancelled", "Report not deleted", "info");
		}
	};

	const Member = ({ name, email }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography display="block" variant="button" fontWeight="medium">
					{name}
				</MDTypography>
				<MDTypography variant="caption">{email}</MDTypography>
			</MDBox>
		</MDBox>
	);

	const DropdownMenu = ({ userId, member }) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handleOpenMenu = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleCloseMenu = () => {
			setAnchorEl(null);
		};

		const handleRoleChange = () => {
			changeUserRole(userId);
			handleCloseMenu();
		};

		const handleStatusChange = () => {
			changeUserStatus(userId);
			handleCloseMenu();
		};

		const handleDelete = () => {
			deleteMember(userId);
			handleCloseMenu();
		};

		const handleViewUer = () => {
			navigate(`/member-profile/${userId}`);
			handleCloseMenu();
		};

		return (
			<>
				<IconButton onClick={handleOpenMenu}>
					<Icon>more_vert</Icon>
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleCloseMenu}
				>
					<MenuItem onClick={() => handleViewUer()}>View</MenuItem>
					{["Admin", "Super-Admin", "State-Manager", "Tech"].includes(
						user?.role,
					) && <MenuItem onClick={() => handleRoleChange()}>Change Role</MenuItem>}
					{["Admin", "Super-Admin", "State-Manager", "Tech"].includes(
						user?.role,
					) && (
							<MenuItem onClick={() => handleStatusChange()}>Change Status</MenuItem>
						)}
					{["Admin", "Super-Admin", "State-Manager", "Tech"].includes(
						user?.role,
					) && <MenuItem onClick={() => handleDelete()}>Remove</MenuItem>}
				</Menu>
			</>
		);
	};

	const columns = [
		{ Header: "name", accessor: "name", width: "25%", align: "left" },
		{ Header: "clinic", accessor: "clinic", align: "center" },
		{ Header: "role", accessor: "role", align: "center" },
		{ Header: "status", accessor: "status", align: "center" },
		{ Header: "date joined", accessor: "date", align: "center" },
		...(isAdmin(user) ? [{ Header: "action", accessor: "action", align: "center" }] : []),
	];

	const rows = members.map((m) => {
		return {
			name: <Member name={m?.name} email={m?.email} />,
			clinic: (
				<MDBox lineHeight={1} textAlign="left">
					<MDTypography
						display="block"
						variant="caption"
						color="text"
						fontWeight="medium"
					>
						{elipsis(m?.clinic?.name || "No Clinic", 50)}
					</MDTypography>
				</MDBox>
			),
			role: (
				<MDTypography
					component="a"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{m?.role}
				</MDTypography>
			),
			status: (
				<MDBox ml={-1}>
					<MDBadge
						badgeContent={m?.status}
						color={
							m?.status === "Active"
								? "success"
								: m?.status === "Inactive"
									? "warning"
									: m?.status === "Suspended"
										? "error"
										: "dark"
						}
						variant="gradient"
						size="sm"
					/>
				</MDBox>
			),
			date: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{safeDateFormat(new Date(m?.createdAt), "dd/MM/yyyy")}
				</MDTypography>
			),
			...(isAdmin(user) ? { action: <DropdownMenu userId={m?._id} member={m} /> } : {}),
		};
	});

	return (
		<>
			<MemberRole
				open={openRoleModal}
				handleClose={() => setOpenRoleModal(false)}
				userId={selectedUserId}
				updateMembers={fetchMembers}
			/>
			<MemberStatus
				open={openStatusModal}
				handleClose={() => setOpenStatusModal(false)}
				userId={selectedUserId}
				updateMembers={fetchMembers}
			/>

			<MDBox pb={1}>
				{loading ? (
					<MDBox display="flex" justifyContent="center" alignItems="center">
						<MDTypography variant="h6">
							<MDBox display="flex" justifyContent="center" alignItems="center">
								<CircularProgress color="info" />
							</MDBox>{" "}
							Loading...
						</MDTypography>
					</MDBox>
				) : null}
				<MDBox>
					{members.length === 0 ? (
						<MDBox pb={1}>
							<MDTypography flex="1" mx={4} color="text" align="center">
								No data available
							</MDTypography>
						</MDBox>
					) : (
						<DataTable
							table={{ columns, rows }}
							isSorted={false}
							entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
							showTotalEntries={true}
							noEndBorder
						/>
					)}
				</MDBox>
			</MDBox>
		</>
	);
}

export default AllMembers;
