import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Grid, Card, IconButton, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBadge from "components/MDBadge";

import DataTable from "utilities/Tables/DataTable";
import { viewCaseFile } from "services/caseFileService";
import { safeDateFormat } from "utils";
import { useAuth } from "context/auth";
import SearchBar from "components/search-bar";

const CaseFileDetails = () => {
	const { id } = useParams();
	const { user } = useAuth();
	const navigate = useNavigate();
	const [keyword, setKeyword] = useState("");
	const [date, setDate] = useState("");
	const [loading, setLoading] = useState(true);
	const [fileData, setFileData] = useState({});
	const [reports, setReports] = useState([]);

	const fetchCaseFile = useCallback(async () => {
		try {
			const response = await viewCaseFile(id);
			const data = response?.data || {};
			setFileData(data);
			setReports(Array.isArray(data.reports) ? data.reports : []);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching Case File:", error);
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		if (id) {
			fetchCaseFile();
		}
	}, [fetchCaseFile, id]);

	const onSearch = async (entry) => {
		setLoading(true);
		try {
			setKeyword(entry);
			const res = await viewCaseFile(id, date);
			const { data } = res.data;
			setFileData(data);
		} catch (error) {
			console.log("Search Error", error.message);
		}
	};

	const columns = [
		{
			Header: "File ID",
			accessor: "file_id",
			width: "20%",
			align: "left",
		},
		{
			Header: "Activities",
			accessor: "total_reports",
			align: "center",
		},
		{
			Header: "Status",
			accessor: "status",
			align: "center",
		},
		{
			Header: "Date Created",
			accessor: "date_created",
			align: "center",
		},
		{
			Header: "Last Updated",
			accessor: "last_updated",
			align: "center",
		},
	];

	const rows = (reports || []).map((item) => {
		return {
			file_id: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{item?.rpdnId || "N/A"}
				</MDTypography>
			),
			total_reports: item?.reports && item?.reports?.length > 0 ? item.reports.length : 0,
			status: (
				<MDBox ml={-1}>
					<MDBadge
						badgeContent={item?.status || "n/a"}
						color={
							item?.status === "Open"
								? "success"
								: item?.status === "Closed"
									? "dark"
									: "error"
						}
						variant="gradient"
						size="sm"
					/>
				</MDBox>
			),
			date_created: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{item?.createdAt ? safeDateFormat(new Date(item.createdAt), "dd/MM/yyyy") : "N/A"}
				</MDTypography>
			),
			last_updated: (
				<MDTypography
					component="a"
					href="#"
					variant="caption"
					color="text"
					fontWeight="medium"
				>
					{item?.updatedAt ? safeDateFormat(new Date(item.updatedAt), "dd/MM/yyyy") : "N/A"}
				</MDTypography>
			),
			action: (
				<IconButton
					onClick={() => navigate(`/report/${item._id}`)}
					sx={{ p: 1, bgcolor: "transparent" }}
				>
					<Icon>visibility</Icon>
				</IconButton>
			),
		};
	});

	if (loading) {
		return (
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100vh"
			>
				<CircularProgress color="info" />
			</MDBox>
		);
	}

	if (!fileData) {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="100vh"
				>
					<MDTypography variant="h5">No data yet</MDTypography>
				</MDBox>
				<Footer />
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox p={3}>
				<MDTypography variant="h6" gutterBottom>
					File Identifier: {fileData.rpdnId}
				</MDTypography>
				<MDTypography variant="h6" gutterBottom>
					Total Reports: {fileData?.reports && fileData?.reports?.length > 0 ? fileData.reports.length : 0}
				</MDTypography>
				<MDTypography variant="h6" gutterBottom>
					Status: {fileData.status}
				</MDTypography>

				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography variant="h6" color="white">
										Case File Reports
									</MDTypography>
								</MDBox>
								<MDBox pt={3}>
									{loading ? (
										<MDBox display="flex" justifyContent="center" alignItems="center">
											<MDTypography variant="h6">
												<MDBox
													display="flex"
													justifyContent="center"
													alignItems="center"
													minHeight="100vh"
												>
													<CircularProgress color="info" />
												</MDBox>{" "}
												Loading...
											</MDTypography>
										</MDBox>
									) : null}
									{rows && rows?.length === 0 ? (
										<MDBox display="flex" justifyContent="center" alignItems="center">
											<MDTypography variant="h6">No Records Found</MDTypography>
										</MDBox>
									) : (
										<>
											<MDBox px={2} display="flex" justifyContent="space-between">
												<div></div>
												<SearchBar
													onSearch={onSearch}
													searchTerm={keyword}
													setSearchTerm={setKeyword}
												/>
											</MDBox>
											<MDBox>
												<DataTable
													table={{ columns: columns, rows: rows }}
													isSorted={false}
													entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
													showTotalEntries={true}
													noEndBorder
												/>
											</MDBox>
										</>
									)}
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
};

export default CaseFileDetails;
