import { format as formatDate } from "date-fns";

export function safeDateFormat(date, formatStr) {
    try {
        return formatDate(new Date(date), formatStr);
    } catch (error) {
        return "n/a";
    }
}

export const elipsis = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
};

export const isAdmin = (user) => {
    return user ? ["Tech", "Admin", "Super-Admin"].includes(user.role) : false;
}

export const isMaster = (user) => {
    return user ? ["Tech", "Super-Admin"].includes(user.role) : false;
}

export const isLawyer = (user) => user ? ["Tech", "Lawyer", "Clinician"].includes(user.role) : false;

export const isPDSS = (user) => user ? ["Tech", "PDSS"].includes(user.role) : false;