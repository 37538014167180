// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import ComplexStatisticsCard from "utilities/Cards/StatisticsCards/ComplexStatisticsCard";
import { Bar, Pie } from 'react-chartjs-2';
import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "context/auth";
import {
	getActiveLocationsCount,
	getTotalClinics,
	getTotalMembers,
	getGenderDistribution,
	getPWDDistribution,
	getPlacesOfArrestData,
	getCustodialCentersData,
	getDateOfArrestData,
	getIncomeLevelData,
	getReviewWeeklySummary,
	getDetaineeWeeklySummary,
	getConferenceWeeklySummary,
} from "services/analyticsService";
import YearPicker from "components/year-picker";
import StatePicker from "components/State-picker";
import { Card, Divider, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const getRandomColor = () => {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

function Analytics() {
	const { user } = useAuth();
	const [clinicData, setClinicData] = useState(0);
	const [locationData, setLocationData] = useState({ totalActiveLocations: 0 });
	const [memberData, setMemberData] = useState(0);
	const [year, setYear] = useState(new Date().getFullYear());
	const [state, setState] = useState("");
	const [loading, setLoading] = useState(true);

	const [genderDistributionMembers, setGenderDistributionMembers] = useState({});
	const [pwdDistributionMembers, setPWDDistributionMembers] = useState({});
	const [placesOfArrestData, setPlacesOfArrestData] = useState({});
	const [custodialCentersData, setCustodialCentersData] = useState({});
	const [dateOfArrestData, setDateOfArrestData] = useState({});
	const [incomeLevelData, setIncomeLevelData] = useState({});
	const [weeklyReviewData, setWeeklyReviewData] = useState({});
	const [weeklyDetaineeData, setWeeklyDetaineeData] = useState({});
	const [weeklyConferenceData, setWeeklyConferenceData] = useState({});

	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			const fallbackState = state || "default-state";
			const fallbackYear = year || new Date().getFullYear();

			const [cRes, lRes, mRes, genderRes, pwdRes, placesRes, custodialRes, dateRes, incomeRes, weeklyRevRes, weeklyDetRes, weeklyConfRes] = await Promise.all([
				getTotalClinics(fallbackYear, fallbackState),
				getActiveLocationsCount(fallbackYear, fallbackState),
				getTotalMembers(fallbackYear, fallbackState),
				getGenderDistribution(fallbackYear, fallbackState),
				getPWDDistribution(fallbackYear, fallbackState),
				getPlacesOfArrestData(fallbackYear, fallbackState),
				getCustodialCentersData(fallbackYear, fallbackState),
				getDateOfArrestData(fallbackYear, fallbackState),
				getIncomeLevelData(fallbackYear, fallbackState),
				getReviewWeeklySummary(),
				getDetaineeWeeklySummary(),
				getConferenceWeeklySummary(),
			]);

			setLocationData(lRes.data);
			setClinicData(cRes?.data?.totalClinics || 0);
			setMemberData(mRes?.data?.totalMembers || 0);

			const setChartData = (data) => {
				if (data && data.labels && data.values) {
					return {
						labels: data.labels,
						datasets: [{
							data: data.values,
							backgroundColor: data.values.map(() => getRandomColor()),
						}],
					};
				}
				return { labels: [], datasets: [] }; 
			};

			setGenderDistributionMembers(setChartData(genderRes.data));
			setPWDDistributionMembers(setChartData(pwdRes.data));
			setPlacesOfArrestData(setChartData(placesRes.data));
			setCustodialCentersData(setChartData(custodialRes.data));
			setDateOfArrestData(setChartData(dateRes.data));
			setIncomeLevelData(setChartData(incomeRes.data));

			setWeeklyReviewData(setChartData(weeklyRevRes.data || { labels: [], values: [] }));
			setWeeklyDetaineeData(setChartData(weeklyDetRes.data || { labels: [], values: [] }));
			setWeeklyConferenceData(setChartData(weeklyConfRes.data || { labels: [], values: [] }));

		} catch (error) {
			console.error("Dashboard data fetch error:", error);
		} finally {
			setLoading(false);
		}
	}, [year, state]);

	useEffect(() => {
		fetchData();
	}, [year, state, fetchData]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox my={1} px={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<MDBox color="text" fontWeight="light">
								<YearPicker selectedYear={year} onYearChange={setYear} />
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<MDBox color="text" fontWeight="light">
								<StatePicker selectedState={state} onStateChange={setState} />
							</MDBox>
						</Grid>
					</Grid>
					<Divider />
				</MDBox>
				<MDBox py={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="success"
									icon="account_balance"
									title="LAW CLINICS"
									count={clinicData || 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of clinics",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color="dark"
									icon="person"
									title="Members (Lawyers and Interns)"
									count={memberData || 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of members",
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									icon="location_on"
									title="Active Locations"
									count={locationData.totalActiveLocations || 0}
									percentage={{
										color: "success",
										amount: "",
										label: "Total number of active locations",
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<MDBox mt={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">Gender Distribution of Members</MDTypography>
										{genderDistributionMembers.labels.length > 0 ? (
											<Pie data={genderDistributionMembers} options={{ maintainAspectRatio: true }} />
										) : (
											<Typography variant="body1" color="textSecondary">No data available for gender distribution.</Typography>
										)}
									</Card>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">PWD Distribution of Members</MDTypography>
										{pwdDistributionMembers.labels.length > 0 ? (
											<Pie data={pwdDistributionMembers} options={{ maintainAspectRatio: true }} />
										) : (
											<Typography variant="body1" color="textSecondary">No data available for PWD distribution.</Typography>
										)}
									</Card>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mt={3}>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">Places of Arrest of Detainees</MDTypography>
										<Bar data={placesOfArrestData} options={{ maintainAspectRatio: true, indexAxis: 'y' }} height={150} />
									</Card>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">Custodial Center Detainees Number</MDTypography>
										<Bar data={custodialCentersData} options={{ maintainAspectRatio: true }} height={150} />
									</Card>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mt={3}>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">Date of Arrest (Monthly)</MDTypography>
										<Bar data={dateOfArrestData} options={{ maintainAspectRatio: true }} />
									</Card>
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MDBox>
									<Card sx={{ p: 10 }}>
										<MDTypography variant="h6" fontWeight="medium">Income Level of Detainees (₦)</MDTypography>
										<Bar data={incomeLevelData} options={{ maintainAspectRatio: true }} />
									</Card>
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mt={3}>
							<Grid item xs={12} md={6}>
								<MDBox>
									<MDTypography variant="h6" fontWeight="medium">Weekly Review Data</MDTypography>
									<Bar data={weeklyReviewData} options={{ maintainAspectRatio: true }} />
								</MDBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MDBox>
									<MDTypography variant="h6" fontWeight="medium">Weekly Detainee Data</MDTypography>
									<Bar data={weeklyDetaineeData} options={{ maintainAspectRatio: true }} />
								</MDBox>
							</Grid>
						</Grid>
						<Grid container spacing={3} mt={3}>
							<Grid item xs={12} md={6}>
								<MDBox>
									<MDTypography variant="h6" fontWeight="medium">Weekly Conference Data</MDTypography>
									<Bar data={weeklyConferenceData} options={{ maintainAspectRatio: true }} />
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
				<Footer />
			</DashboardLayout>
		</>
	);
}

export default Analytics;
