import httpService from "./httpService";

// Function to create a new PDSS report
export const createPdssReport = async (form) => {
    try {
        const response = await httpService.post(`/pdss-report/create/`, form);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(
                err.response.data.message || "Something went wrong. Please try again"
            );
        }
        throw new Error(err.message || "An unknown error occurred");
    }
};

// Function to get all PDSS reports with pagination and filtering
export const getAllPdssReports = async (page, limit, keyword, query) => {
    let url = `/pdss-report/reports`;
    const params = [];

    try {
        if (page) {
            params.push(`page=${encodeURIComponent(page)}`);
        }
        if (limit) {
            params.push(`limit=${encodeURIComponent(limit)}`);
        }
        if (keyword) {
            params.push(`keyword=${encodeURIComponent(keyword)}`);
        }
        if (query) {
            params.push(`query=${encodeURIComponent(query)}`);
        }
        if (params.length > 0) {
            url += `?${params.join("&")}`;
        }
        const response = await httpService.get(url);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(
                err.response.data.message || "Something went wrong. Please try again"
            );
        }
        throw new Error(err.message || "An unknown error occurred");
    }
};

// Function to delete a PDSS report by ID
export const deletePdssReport = async (id) => {
    try {
        const response = await httpService.delete(`/pdss-report/${id}/delete`);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(
                err.response.data.message || "Something went wrong. Please try again"
            );
        }
        throw new Error(err.message || "An unknown error occurred");
    }
};

// Function to update a PDSS report by ID
export const updatePdssReport = async (id, payload) => {
    try {
        const response = await httpService.put(
            `/pdss-report/${id}/update`,
            payload
        );
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(
                err.response.data.message || "Something went wrong. Please try again"
            );
        }
        throw new Error(err.message || "An unknown error occurred");
    }
};

// Function to view a specific PDSS report by ID
export const viewPdssReport = async (id) => {
    try {
        const response = await httpService.get(`/pdss-report/${id}/view`);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw new Error(
                err.response.data.message || "Something went wrong. Please try again"
            );
        }
        throw new Error(err.message || "An unknown error occurred");
    }
}; 