import { useState, useEffect } from "react";
import DashboardLayout from "utilities/LayoutContainers/DashboardLayout";
import DashboardNavbar from "utilities/Navbars/DashboardNavbar";
import Footer from "utilities/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Autocomplete from "@mui/lab/Autocomplete";
import { Grid, Card } from "@mui/material";
import { toast } from "react-toastify";
import NaijaStates from "naija-state-local-government";
import { createCaseConference } from "services/caseConferenceService";
import { getAllClinics } from "../../services/clinicService";
import Editor from "components/Editor";
import { getAllUsers } from "services/userService";
import { useNavigate } from "react-router-dom";
import PrintableAndDownloadable from "components/print-download";

function CaseConferenceForm() {
    const [signatureOfClinicGroupHead, setSignatureOfClinicGroupHead] =
        useState("");
    const [signatureOfLawyer, setSignatureOfLawyer] = useState("");
    const [signatureOfSupervisor, setSignatureOfSupervisor] = useState("");
    const [conferenceLocation, setConferenceLocation] = useState("");
    const [conferenceDate, setConferenceDate] = useState(Date.now());
    const [participants, setParticipants] = useState([]);
    const [members, setMembers] = useState([]);
    const [listOfCasesDiscussed, setListOfCasesDiscussed] = useState([""]);
    const [lawyerNames, setLawyerNames] = useState([""]);

    const [note, setNote] = useState("");
    const [locationState, setLocationState] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);

    const navigate = useNavigate();

    const stateData = NaijaStates.states().map((item, idx) => ({
        id: idx,
        name: item,
        value: item,
    }));

    const fetchClinics = async () => {
        try {
            const res = await getAllClinics();
            const { data } = res.data;
            setClinics(data);
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        }
    };

    const fetchMembers = async () => {
        try {
            const res = await getAllUsers();
            const { data } = res.data;
            console.log(data);
            setMembers(data);
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        }
    };

    useEffect(() => {
        fetchClinics();
        fetchMembers();
    }, []);

    const handleClinicChange = (event, newValue) => {
        setSelectedClinic(newValue);
    };

    const handleStateChange = (event, value) => {
        setLocationState(value ? value.name : null);
    };

    const fetchLocation = () => {
        if (!navigator.geolocation) {
            toast("Geolocation is not supported by this browser.", {
                type: "error",
                autoClose: 3000,
            });
            return;
        }

        // show loader
        setLoading(true);

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                reverseGeocode(latitude, longitude);
                setLoading(false);
            },
            (error) => {
                toast("Unable to retrieve your location", {
                    type: "error",
                    autoClose: 3000,
                });
                setLoading(false);
            }
        );
    };

    const reverseGeocode = (latitude, longitude) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(latitude, longitude);

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    const state = addressComponents.find((item) =>
                        item.types.includes("administrative_area_level_1")
                    );
                    setLocationState(state.long_name || "Unknown State");
                } else {
                    setLocationState("Please select a state");
                }
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        fetchLocation();
    }, []);

    const handleFormClear = () => {
        // Reset form
        setSignatureOfClinicGroupHead("");
        setSignatureOfLawyer("");
        setSignatureOfSupervisor("");
        setSelectedClinic(null);
        setConferenceLocation("");
        setConferenceDate(null);
        setParticipants([]);
        setListOfCasesDiscussed([]);
        setNote("");
        setLocationState("");
    };

    const handleCaseChange = (index, value) => {
        const updatedCases = [...listOfCasesDiscussed];
        updatedCases[index] = value;
        setListOfCasesDiscussed(updatedCases);
    };

    const handleAddCase = () => {
        setListOfCasesDiscussed([...listOfCasesDiscussed, ""]);
    };

    const handleRemoveCase = (index) => {
        const updatedCases = [...listOfCasesDiscussed];
        updatedCases.splice(index, 1);
        setListOfCasesDiscussed(updatedCases);
    };

    const handleNameChange = (index, value) => {
        const updatedNames = [...lawyerNames];
        updatedNames[index] = value;
        setLawyerNames(updatedNames);
    };

    const handleAddName = () => {
        setLawyerNames([...lawyerNames, ""]);
    };

    const handleRemoveName = (index) => {
        const updatedNames = [...lawyerNames];
        updatedNames.splice(index, 1);
        setLawyerNames(updatedNames);
    };

    const handleCancel = () => {
        handleFormClear();
        window.localStorage.setItem("currentTabIndex", 1);
        navigate(-1);
    };

    const handleFormSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        const formData = {
            // signatureOfClinicGroupHead,
            signatureOfLawyer,
            signatureOfSupervisor,
            nameOfLawClinic: selectedClinic?.name,
            conferenceLocation,
            conferenceDate,
            internalParticipants: participants,
            listOfCasesDiscussed,
            externalParticipants: lawyerNames,
            note,
            location: {
                state: locationState,
                // latitude,
                // longitude,
            },
        };

        try {
            const res = await createCaseConference(formData);
            const { message, status } = res;
            if (status === "success") {
                toast(message, { type: "success", autoClose: 3000 });
                handleFormClear();
                setIsLoading(false);
                navigate('/report');
            } else {
                toast(message, { type: "error", autoClose: 3000 });
            }
        } catch (error) {
            toast(error.message, { type: "error", autoClose: 3000 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <PrintableAndDownloadable filename="caseconfform.pdf">
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MDTypography
                                        variant="h6"
                                        color="white"
                                        fontWeight="medium"
                                        textTransform="capitalize"
                                    >
                                        Case Conference Report Form
                                    </MDTypography>
                                </MDBox>
                                <MDBox pt={6} pb={3} px={2}>
                                    <MDBox px={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <MDInput
                                                    type="date"
                                                    variant="standard"
                                                    label="Conference Date"
                                                    fullWidth
                                                    value={conferenceDate}
                                                    onChange={(e) =>
                                                        setConferenceDate(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div>
                                                    <Autocomplete
                                                        value={
                                                            stateData.find(
                                                                (item) =>
                                                                    item.name ===
                                                                    locationState
                                                            ) || null
                                                        }
                                                        onChange={handleStateChange}
                                                        options={stateData}
                                                        getOptionLabel={(option) =>
                                                            option.name
                                                        }
                                                        renderInput={(params) => (
                                                            <MDInput
                                                                {...params}
                                                                type="text"
                                                                label="State of Conference"
                                                                variant="standard"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Latitude"
                                                fullWidth
                                                value={latitude}
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Longitude"
                                                fullWidth
                                                value={longitude}
                                                readOnly
                                            />
                                        </Grid> */}
                                        </Grid>
                                        <MDBox mb={2} mt={3}>
                                            <Autocomplete
                                                value={selectedClinic}
                                                onChange={handleClinicChange}
                                                options={clinics || []}
                                                getOptionLabel={(option) =>
                                                    option.name ? option.name : ""
                                                }
                                                isOptionEqualToValue={(option, value) => option._id === value?._id}
                                                renderInput={(params) => (
                                                    <MDInput
                                                        {...params}
                                                        type="text"
                                                        label="Name of Law Clinic"
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput
                                                type="text"
                                                variant="standard"
                                                label="Location of Case Conference"
                                                fullWidth
                                                value={conferenceLocation}
                                                onChange={(e) =>
                                                    setConferenceLocation(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <Autocomplete
                                                multiple
                                                options={members}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                value={participants.map((_id) =>
                                                    members.find(
                                                        (member) =>
                                                            member._id === _id
                                                    )
                                                )}
                                                onChange={(event, newValue) =>
                                                    setParticipants(
                                                        newValue.map(
                                                            (member) => member._id
                                                        )
                                                    )
                                                }
                                                renderInput={(params) => (
                                                    <MDInput
                                                        {...params}
                                                        variant="standard"
                                                        label="List of Clinicians Present (Select multiple)"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </MDBox>
                                        <MDBox mb={2} sx={{ mt: 4 }}>
                                            <MDTypography variant="h6">
                                                List of Cases Discussed and
                                                Outcomes/Next steps
                                            </MDTypography>
                                            {listOfCasesDiscussed.map(
                                                (caseItem, index) => (
                                                    <MDBox
                                                        key={index}
                                                        display="flex"
                                                        alignItems="center"
                                                        mb={1}
                                                    >
                                                        <MDInput
                                                            type="text"
                                                            variant="standard"
                                                            placeholder="Type case and its outcome here..."
                                                            fullWidth
                                                            value={caseItem}
                                                            onChange={(e) =>
                                                                handleCaseChange(
                                                                    index,
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <MDButton
                                                            variant="text"
                                                            color="error"
                                                            onClick={() =>
                                                                handleRemoveCase(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </MDButton>
                                                    </MDBox>
                                                )
                                            )}
                                            <MDButton
                                                variant="outlined"
                                                color="dark"
                                                size="small"
                                                onClick={handleAddCase}
                                            >
                                                Add Case
                                            </MDButton>
                                        </MDBox>

                                        <MDBox mb={2} sx={{ mt: 4 }}>
                                            <MDTypography
                                                variant="h6"
                                                color="textSecondary"
                                            >
                                                Name of Lawyer(s)
                                            </MDTypography>
                                            {lawyerNames.map((name, index) => (
                                                <MDBox
                                                    key={index}
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={1}
                                                >
                                                    <MDInput
                                                        type="text"
                                                        variant="standard"
                                                        placeholder="Enter lawyer's name..."
                                                        fullWidth
                                                        value={name}
                                                        onChange={(e) =>
                                                            handleNameChange(
                                                                index,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <MDButton
                                                        variant="text"
                                                        color="error"
                                                        onClick={() =>
                                                            handleRemoveName(index)
                                                        }
                                                    >
                                                        Remove
                                                    </MDButton>
                                                </MDBox>
                                            ))}
                                            <MDButton
                                                variant="outlined"
                                                color="dark"
                                                size="small"
                                                onClick={handleAddName}
                                            >
                                                Add Lawyer
                                            </MDButton>
                                        </MDBox>

                                        <MDBox mb={2}>
                                            <Editor
                                                title={"Notes"}
                                                content={note}
                                                handleInputChange={(e) =>
                                                    setNote(e.target.value)
                                                }
                                            />
                                        </MDBox>

                                        <MDBox
                                            mt={3}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <MDBox sx={{ display: "flex" }}>
                                                <MDButton
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </MDButton>
                                                <MDButton
                                                    sx={{ ml: 1 }}
                                                    variant="outlined"
                                                    color="warning"
                                                    onClick={() => handleFormClear()}
                                                >
                                                    Clear
                                                </MDButton>

                                            </MDBox>
                                            <MDButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                disabled={isLoading}
                                                onClick={handleFormSubmit}
                                            >
                                                {isLoading
                                                    ? "Submitting..."
                                                    : "Submit"}
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </PrintableAndDownloadable>
            <Footer />
        </DashboardLayout>
    );
}

export default CaseConferenceForm;
